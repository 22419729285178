import { useTranslation } from 'react-i18next'

import type { FooterProps } from '../../components/Footer'
import type { Configuration } from '../../rest/types/Configuration'

const useFooter = (configuration: Configuration): FooterProps => {
  const { t } = useTranslation()

  return {
    topLinksLabel: t('topLinksLabel'),
    copyright: t('copyright'),
    topLinks: configuration.menus?.footer?.entries?.map((entry) => ({
      text: entry.title,
      href: entry.url,
      target: entry.target,
    })),
    legalLinks: configuration.menus?.legal?.entries?.map((entry) => ({
      text: entry.title,
      href: entry.url,
      target: entry.target,
    })),
  }
}

export default useFooter

import styled, { css } from 'styled-components'

import Link from '../Link'

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.greenHeader};
  ${({ theme }) => theme.textStyles.firaSansSmMedium};
  text-transform: uppercase;
`
export const Text = styled.div`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const TagLine = styled.div``

export const Lk = styled(Link)<{ $color?: string }>`
  padding-left: 1.6rem;
  border-left: 2px solid ${({ theme }) => theme.colors.greenHeader};
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;

  ${({ $color }) =>
    $color &&
    css`
      border-left-color: ${$color};

      ${Title} {
        color: ${$color};
      }

      ${Text} {
        color: ${$color};
      }
    `};
`

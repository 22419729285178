import styled, { css } from 'styled-components'

import Link from '../Link'
import Icon from '../Icon'
import LinkBack from '../LinkBack'
import ButtonIcon from '../ButtonIcon'

export const Top = styled.div`
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  background: ${({ theme }) => theme.colors.greenSecondaryBis};

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    padding: 0 4rem;
    height: 5rem;
  }
`

export const Left = styled.div`
  ${({ theme }) => theme.breakpoints.down('desktop')} {
    height: 5rem;
    display: flex;
    padding: 0 1.2rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`

export const Right = styled.div<{ $isOpen?: boolean }>`
  ${({ theme }) => theme.breakpoints.down('desktop')} {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.greenHeader};
    transition: all 0.5s ease;

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        max-height: 170px;
      `};
  }
`

export const RightInner = styled.div`
  display: flex;
  gap: 3rem;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-direction: column;
    padding: 2rem 2.4rem;
    transition: all 0.5s ease-in;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-direction: column;
    align-items: flex-start;

    ${Left} {
      order: 2;
    }

    ${Right} {
      order: 1;
    }
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.pureWhite};
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const PhoneIcon = styled(Icon)`
  margin-right: 1rem;
`
export const MessageIcon = styled(Icon)`
  margin-right: 1rem;
`

export const Helpers = styled.div`
  display: flex;
  gap: 3rem;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-direction: column;
  }
`

export const Back = styled(LinkBack)`
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const MobileIcon = styled(ButtonIcon)`
  ${({ theme }) => theme.breakpoints.up('desktop')} {
    display: none;
  }
`

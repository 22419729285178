import styled from 'styled-components'

import Link from '../Link'
import Icon from '../Icon'
import Avatar from '../Avatar'
import Menu from '../Menu'
import ActionButton from '../ActionButton'
import { mq, sizes } from '../../theme'

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  background: ${({ theme }) => theme.colors.blueBack};
  padding: 1.6rem;

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    padding: 0 4rem;
    height: 12rem;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-wrap: wrap;
  }
`

export const MenuStyled = styled(Menu)`
  flex-grow: 1;
  align-items: flex-start;
  padding: 0 4rem;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-basis: 100%;
    order: 3;
    align-items: center;
    padding: 0;
  }
`

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.pureWhite};
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const Logo = styled(Icon)`
  ${({ theme }) => theme.breakpoints.down('desktop')} {
    width: 15rem;
    order: 1;
  }
`

export const UserAvatar = styled(Avatar)`
  ${({ theme }) => theme.breakpoints.down('desktop')} {
    order: 2;
  }
`

export const LoginButton = styled(ActionButton)`
  order: 2;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.pinkButton};
  border-color: ${({ theme }) => theme.colors.pinkButton};

  @media ${mq(null, sizes.desktop)} {
    padding: 0.5rem 1rem;
  }
`

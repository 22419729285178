import styled from 'styled-components'

import Social from '../Social'

export const Socials = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const Item = styled(Social)``

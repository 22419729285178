import { FC } from 'react'
import { useTheme } from 'styled-components'

import Link, { LinkProps } from '../Link'

import * as SC from './styled'

export type LinkBackProps = LinkProps & {
  className?: string
}

const LinkBack: FC<LinkBackProps> = ({ className, ...props }) => {
  const theme = useTheme()

  return (
    <Link className={className} {...props}>
      <SC.IconStyled
        icon="chevronLeft"
        width={12}
        color={theme.colors.bluePrimary}
      />
      {props.text}
    </Link>
  )
}

export default LinkBack

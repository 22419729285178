import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MegaMenuProps } from '../../components/MegaMenu'
import { Categories } from '../../rest/types/Categories'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'

const useMegaMenu = ({
  categories,
  isOpen,
  handleClose,
}: {
  categories: Categories
  isOpen?: boolean
  handleClose?: () => void
}): MegaMenuProps => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('')
  const { t } = useTranslation()
  const parentCategories = useMemo(
    () => categories?.filter((category) => category.parentCategory === null),
    [categories]
  )
  const childCategories = useMemo(
    () => categories?.filter((category) => category.parentCategory !== null),
    [categories]
  )

  const selectedChildCategories = useMemo(
    () =>
      childCategories?.filter(
        (c) => c?.parentCategory?.id === selectedCategoryId
      ),
    [childCategories, selectedCategoryId]
  )

  const selectedCategory = categories?.find((c) => c.id === selectedCategoryId)

  useEffect(() => {
    if (!isOpen) {
      setSelectedCategoryId('')
    }
  }, [isOpen])

  return {
    categories: {
      title: t('megaMenuTitle'),
      items: parentCategories.map((category) => ({
        title: category.name,
        description: category.shortDescription,
        iconCategory: category.icon,
        selected: selectedCategoryId === category.id,
        list: {
          links: selectedChildCategories.map((subCat) => ({
            text: subCat.name,
            onClick: handleClose,
            href: Router.getRouteUrl(routes.category, { slug: subCat.slug }),
          })),
        },
        ...(selectedCategoryId === category.id && {
          link: {
            onClick: handleClose,
            href: Router.getRouteUrl(routes.category, { slug: category.slug }),
          },
        }),
        onClick: () => {
          setSelectedCategoryId(category.id)
        },
      })),
    },
    sidebar: {
      category: {
        name: selectedCategory?.name ?? '',
        iconCategory: selectedCategory?.icon,
        link: {
          onClick: handleClose,
          href: selectedCategory?.slug
            ? Router.getRouteUrl(routes.category, {
                slug: selectedCategory?.slug,
              })
            : '',
        },
      },
      list: {
        links: selectedChildCategories.map((subCat) => ({
          onClick: handleClose,
          href: Router.getRouteUrl(routes.category, { slug: subCat.slug }),
          text: subCat.name,
        })),
      },
    },
    isSidebarOpen: !!selectedCategoryId,
  }
}

export default useMegaMenu

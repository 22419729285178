import { FC } from 'react'
import cx from 'classnames'
import { useElementSize } from 'usehooks-ts'
import { useTheme } from 'styled-components'

import { LinkProps } from '../Link'
import { IconProps } from '../Icon'

import * as SC from './styled'

export type MenuItem = LinkProps & {
  isSelected?: boolean
  icon?: IconProps
}

export type MenuProps = {
  className?: string
  title?: string
  isCollapsed?: boolean
  onClick?: () => void
  items: Array<MenuItem>
}

const Menu: FC<MenuProps> = ({
  className,
  items,
  title,
  isCollapsed,
  onClick,
}) => {
  const theme = useTheme()
  const [ref, { height }] = useElementSize<HTMLUListElement>()

  return (
    <SC.Menu className={cx('Menu', className)}>
      <SC.Button
        icon="chevronUp"
        width={16}
        color={theme.colors.bluePrimary}
        {...(onClick ? { onClick } : {})}
        $collapsed={isCollapsed}
      >
        <SC.ButtonText $collapsed={isCollapsed}>{title}</SC.ButtonText>
      </SC.Button>
      <SC.Wrapper $collapsed={isCollapsed} $maxHeight={height}>
        <SC.List ref={ref}>
          {items?.map((item, idx) => (
            <SC.Item key={idx} $isSelected={item.isSelected}>
              <SC.Lk {...item}>
                {item.icon && (
                  <SC.ItemIcon
                    {...item.icon}
                    {...(item.isSelected
                      ? { color: theme.colors.pinkLink }
                      : {})}
                  />
                )}
                <SC.Span>{item.text}</SC.Span>
              </SC.Lk>
            </SC.Item>
          ))}
        </SC.List>
      </SC.Wrapper>
    </SC.Menu>
  )
}

export default Menu

import styled from 'styled-components'

import Icon from '../Icon'

export const Button = styled.button`
  background-color: transparent;
  border: none;
`

export const IconStyled = styled(Icon)``

import React, { FC } from 'react'
import cx from 'classnames'

import TopBar, { TopProps } from '../TopBar'
import Navigation, { NavigationProps } from '../Navigation'
import BottomBar, { BottomBarProps } from '../BottomBar'

import * as SC from './styled'

export type HeaderProps = {
  className?: string
  topBar: TopProps
  navigation: NavigationProps
  bottomBar?: BottomBarProps
}

const Header: FC<HeaderProps> = ({
  className,
  topBar,
  navigation,
  bottomBar,
}) => {
  return (
    <SC.Header className={cx('Header', className)}>
      <TopBar {...topBar} />
      <Navigation {...navigation} />
      {bottomBar && <BottomBar {...bottomBar} />}
    </SC.Header>
  )
}

export default Header

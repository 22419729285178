import styled, { css } from 'styled-components'

import type { BoxedContainerProps } from './index'

export const Container = styled.div<{
  $maxWidth: BoxedContainerProps['maxWidth']
  $removePadding?: boolean
}>`
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  ${({ $removePadding }) =>
    $removePadding &&
    css`
      padding: 0;
    `}

  ${({ $maxWidth }) =>
    $maxWidth === 'default' &&
    css`
      max-width: calc(var(--boxedContainer-default) + 4rem);
    `}

  ${({ $maxWidth }) =>
    $maxWidth === 'Xsmall' &&
    css`
      max-width: calc(var(--boxedContainer-Xsmall) + 4rem);
    `}
  
  ${({ $maxWidth }) =>
    $maxWidth === 'small' &&
    css`
      max-width: calc(var(--boxedContainer-small) + 4rem);
    `}
`

import routes from '../routes/definitions'

export enum Index {
  //Category = 'category.category',
  //Page = 'page.page',
  Offer = 'offer.offer',
  Classified = 'classified.classified',
}

export const algoliaRoutes = {
  /*[Index.Category]: routes.category,
  [Index.Page]: routes.page,*/
  [Index.Offer]: routes.offer,
  [Index.Classified]: routes.classified,
} as const

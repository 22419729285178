import React, { FC, Fragment } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type SubcategoryListProps = {
  className?: string
  links: Array<LinkProps>
  seeAllLink?: LinkProps
}

const SubcategoryList: FC<SubcategoryListProps> = ({
  className,
  links,
  seeAllLink,
}) => {
  return (
    <SC.SubcategoryList className={cx('SubcategoryList', className)}>
      <SC.Links>
        {links.map((link, idx) => (
          <SC.Lk key={idx} {...link} />
        ))}
        {seeAllLink && (
          <Fragment>
            <SC.Lk text={'----'} />
            <SC.Lk {...seeAllLink} />
          </Fragment>
        )}
      </SC.Links>
    </SC.SubcategoryList>
  )
}

export default SubcategoryList

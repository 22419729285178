import styled, { css } from 'styled-components'

import { mq, sizes } from '../../theme'
import SubcategoryList from '../SubcategoryList'
import Icon from '../Icon'

export const List = styled(SubcategoryList)`
  margin-top: 3rem;
  padding-bottom: 3rem;
`

export const Card = styled.div<{ $selected?: boolean }>`
  position: relative;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  align-items: center;
  padding: 1rem;
  gap: 1.5rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.blueSecondaryBis};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 5px 12px 38px rgba(167, 177, 192, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }

  ${({ $selected, theme }) =>
    $selected &&
    css`
      border-color: ${theme.colors.blueSecondaryBis};
      ${Title} {
        color: ${theme.colors.pureWhite};
        @media ${mq(sizes.desktop)} {
          color: ${({ theme }) => theme.colors.bluePrimary};
        }
      }
    `};

  @media ${mq(sizes.tablet)} {
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  @media ${mq(sizes.desktop)} {
    ${List} {
      display: none;
    }
  }

  @media ${mq(null, sizes.desktop)} {
    ${List} {
      display: none;

      ${({ $selected }) =>
        $selected &&
        css`
          display: block;
        `};
    }

    ${({ $selected }) =>
      $selected &&
      css`
        background-color: ${({ theme }) => theme.colors.blueDark};
        color: ${({ theme }) => theme.colors.pureWhite};
        h4,
        p {
          color: ${({ theme }) => theme.colors.pureWhite};
        }
      `};
  }
`

export const Visible = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const Content = styled.div`
  flex: 1;
  overflow: hidden;
`

export const ImageWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  background: ${({ theme }) => theme.colors.blueBack};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    width: 5rem;
    height: 5rem;
  }
`
export const IconCategory = styled(Icon)``

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.firaSansTextBaseSemiBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin-bottom: 0;
  margin-top: 0;
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.taupeGray};
`

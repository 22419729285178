import styled from 'styled-components'

import CategoryCard from '../../CategoryCard'
import { mq, sizes } from '../../../theme'

export const Menu = styled.div`
  background: ${({ theme }) => theme.colors.blueBack};
  position: relative;
  z-index: 1;
  padding: 4rem 0;
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  margin-bottom: 3rem;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.3rem;
  position: relative;
  z-index: 1;

  @media ${mq(null, sizes.tablet)} {
    grid-template-columns: 1fr;
  }
`

export const Item = styled(CategoryCard)``

export const Radius = styled.div`
  position: absolute;
  @media ${mq(sizes.desktop)} {
    right: 0;
    top: 0;
    width: 3rem;
    height: 100%;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background: ${({ theme }) => theme.colors.blueBack};
    transform: translateX(50%);
    z-index: 1;
  }
`

import React, { FC } from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'

import { LinkProps } from '../Link'
import { sizes } from '../../theme'

import * as SC from './styled'

export type AvatarProps = {
  className?: string
  photo?: ImageProps
  name?: string
  surname?: string
  showRedDot?: boolean
  link?: LinkProps
}

const Avatar: FC<AvatarProps> = ({
  className,
  photo,
  name,
  surname,
  link,
  showRedDot = false,
}) => {
  return (
    <SC.Avatar className={cx('Avatar', className)}>
      {(name || surname) && (
        <SC.BlockInfo>
          {name && <SC.Name>{name}</SC.Name>}
          {surname && <SC.Surname>{surname}</SC.Surname>}
        </SC.BlockInfo>
      )}
      <SC.Lk {...link}>
        <SC.BlockImage className={cx('ImageContainer', className)}>
          {photo && (
            <SC.Photo
              width={60}
              height={60}
              {...photo}
              sizes={`(min-width: ${sizes.tablet}px) 50vw, 70vw`}
            />
          )}
        </SC.BlockImage>
        {showRedDot && <SC.RedDot />}
      </SC.Lk>
    </SC.Avatar>
  )
}

export default Avatar

export type Page = {
  id: string
  name: string
  slug: string
  blocks?: any[]
  pageType: PageType | null | false
}

export enum PageType {
  Store = 'store',
  Classifieds = 'ads',
}

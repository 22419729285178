import React, { FC } from 'react'
import cx from 'classnames'

import { SubcategoryListProps } from '../../SubcategoryList'
import { IconType } from '../../Icon'
import { LinkProps } from '../../Link'

import * as SC from './styled'

export type CategoriesSidebarProps = {
  className?: string
  category?: {
    iconCategory?: string
    name: string
    link: LinkProps
  }
  list?: SubcategoryListProps
}

const CategoriesSidebar: FC<CategoriesSidebarProps> = ({
  className,
  category,
  list,
}) => {
  return (
    <SC.Sidebar className={cx('CategoriesSidebar', className)}>
      {category && (
        <SC.Head>
          <SC.LinkCategory {...category.link}>
            <SC.ImgWrapper>
              {category && category.iconCategory && (
                <SC.IconCategory
                  icon={category.iconCategory as IconType}
                  width={24}
                  height={24}
                />
              )}
            </SC.ImgWrapper>
            <SC.Name>{category.name}</SC.Name>
          </SC.LinkCategory>
        </SC.Head>
      )}
      {list && <SC.List {...list} />}
    </SC.Sidebar>
  )
}

export default CategoriesSidebar

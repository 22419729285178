import React, { FC } from 'react'

import { LinkProps } from '../Link'
import Container from '../BoxedContainer'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  topLinksLabel?: string
  copyright?: string
  topLinks?: Array<LinkProps>
  legalLinks?: Array<LinkProps>
}

const Footer: FC<FooterProps> = ({
  className,
  topLinksLabel,
  topLinks,
  legalLinks,
  copyright,
}) => {
  return (
    <SC.Footer className={className}>
      <Container>
        <SC.Top>
          <SC.TopLinksLabel>{topLinksLabel}</SC.TopLinksLabel>
          {topLinks?.map((link, i) => (
            <SC.LinkWrapper key={`top-link-${i}`}>
              <SC.LinkItem {...link} />
              {i !== topLinks.length - 1 && <SC.LinkSeperator />}
            </SC.LinkWrapper>
          ))}
        </SC.Top>
        <SC.Separator />
        <SC.Bottom>
          <SC.Copyright>{copyright}</SC.Copyright>
          <SC.Separator $isHiddenDesktop />
          <SC.LegalLinks>
            {legalLinks?.map((link, i) => (
              <SC.LinkWrapper key={`legal-link-${i}`}>
                <SC.LinkItem {...link} />
                {i !== legalLinks.length - 1 && <SC.LinkSeperator />}
              </SC.LinkWrapper>
            ))}
          </SC.LegalLinks>
        </SC.Bottom>
      </Container>
    </SC.Footer>
  )
}

export default Footer

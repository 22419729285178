import styled, { css } from 'styled-components'

import MegaMenu from '../../components/MegaMenu'

export const Menu = styled(MegaMenu)<{ $isOpen: boolean }>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  width: 100%;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-3rem);
  transition: all 0.3s ease;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `}
`

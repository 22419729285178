import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type TagLineProps = {
  className?: string
  color?: string
  title: string
  texts: Array<string>
  link: LinkProps
}

const TagLine: FC<TagLineProps> = ({
  className,
  title,
  texts,
  color,
  link,
}) => {
  return (
    <SC.TagLine className={cx('TagLine', className)}>
      <SC.Lk {...link} $color={color}>
        <SC.Title>{title}</SC.Title>
        {texts.map((text, idx) => (
          <SC.Text key={idx}>{text}</SC.Text>
        ))}
      </SC.Lk>
    </SC.TagLine>
  )
}

export default TagLine

import { algoliaRoutes, Index } from '../types/algolia'
import Router from '../routes/Router'

export const resultsTransformer = (results: any, t: any) => {
  let items = results.map((query: any) => {
    const index = parseQueryIndex(query.index)

    const [key] =
      Object.entries(Index).find(([, value]) => value === index) ?? []

    if (!key) return []

    return query.hits.map((item: any) => ({
      value: `${query.index}--${item.slug}`,
      label: `${item.name || item.title} - ${t(`searchResultType${key}`)}`,
    }))
  })

  items = items.reduce((acc: any, item: any) => {
    acc = [...acc, ...item]
    return acc
  }, [])

  return items
}

export const parseQueryIndex = (queryIndex: string): Index => {
  const index = queryIndex.split('::')?.pop()
  return index as Index
}

export const parseQueryIndexWithSlug = (
  queryIndexWithSlug: string
): { index: Index | null; slug: string | null } => {
  let index = null
  let slug = null

  const indexWithSlug = parseQueryIndex(queryIndexWithSlug)

  if (indexWithSlug) {
    const params = indexWithSlug.split('--')
    if (params.length === 2) {
      index = params[0] as Index
      slug = params[1]
    }
  }

  return { index, slug }
}

export const getRouteFromQueryIndex = (queryIndex: string) => {
  const { index, slug } = parseQueryIndexWithSlug(queryIndex)
  const route = algoliaRoutes?.[index as Index]
  return route ? Router.getRouteUrl(route, { slug: slug }) : ''
}

import React, { FC, FormHTMLAttributes } from 'react'

import { Icons, IconType } from '../Icon'
import { FormFieldAutoSuggestProps } from '../../form/fields/FormFieldAutoSuggest'
import { ActionButtonVariant } from '../ActionButton/styled'

import * as SC from './styled'

export type SearchFieldProps = Omit<FormFieldAutoSuggestProps, 'value'> & {
  value?: string
  searchIcon?: IconType
  isOpen: boolean
  formProps?: FormHTMLAttributes<HTMLFormElement>
  texts?: {
    button?: string
  }
  onSubmit?: (value?: string) => void
  onCategoryButtonClick?: () => void
}

const SearchField: FC<SearchFieldProps> = ({
  onSubmit,
  value,
  className,
  texts,
  searchIcon,
  onCategoryButtonClick,
  isOpen,
  formProps,
  ...extraProps
}) => {
  return (
    <SC.Form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit?.(value)
      }}
      role="search"
      {...formProps}
    >
      <SC.Input
        {...extraProps}
        value={value}
        onSubmit={(v: string) => onSubmit?.(v)}
        leftAdornment={<SC.SearchIcon icon={searchIcon ?? Icons.search} />}
        rightAdornment={
          <SC.CategoryButton
            onClick={onCategoryButtonClick}
            variant={ActionButtonVariant.secondary}
            $isOpen={isOpen}
            type="button"
          >
            <SC.CategoryIcon icon={Icons.chevronDown} />
            {texts?.button}
          </SC.CategoryButton>
        }
      />
    </SC.Form>
  )
}

export default SearchField

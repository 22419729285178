import React, { FC, useId } from 'react'
import cx from 'classnames'

import { CategoryCardProps } from '../../CategoryCard'

import * as SC from './styled'

export type CategoryCardListProps = {
  className?: string
  title: string
  items: Array<CategoryCardProps>
}

const CategoryCardList: FC<CategoryCardListProps> = ({
  className,
  title,
  items,
}) => {
  const id = useId()
  return (
    <SC.Menu className={cx('CategoryCardList', className)}>
      <SC.Radius />
      <SC.Title>{title}</SC.Title>
      <SC.Items>
        {items.map((item, idx) => (
          <SC.Item key={`${id}-${idx}`} {...item} />
        ))}
      </SC.Items>
    </SC.Menu>
  )
}

export default CategoryCardList

import { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type BottomBarProps = {
  className?: string
  slotLeft?: React.ReactNode
  slotMiddle?: React.ReactNode
  slotMegaMenu?: React.ReactNode
}

const BottomBar: FC<BottomBarProps> = ({
  className,
  slotLeft,
  slotMiddle,
  slotMegaMenu,
}) => {
  return (
    <>
      <SC.Bottom className={cx('BottomBar', className)}>
        {slotLeft && <SC.Slot $hideMobile>{slotLeft}</SC.Slot>}
        {slotMiddle && <SC.Slot>{slotMiddle}</SC.Slot>}
        <SC.Slot $hideMobile></SC.Slot>
      </SC.Bottom>
      {slotMegaMenu && <SC.Slot>{slotMegaMenu}</SC.Slot>}
    </>
  )
}

export default BottomBar

import React, { FC, useState } from 'react'
import cx from 'classnames'
import { useTheme } from 'styled-components'

import { LinkProps } from '../Link'
import { Icons } from '../Icon'
import { LinkBackProps } from '../LinkBack'

import * as SC from './styled'

export type TopProps = {
  className?: string
  backLink?: LinkBackProps
  supportLink?: LinkProps
  phoneLink?: LinkProps
  socials?: React.ReactNode
}

const TopBar: FC<TopProps> = ({
  className,
  socials,
  backLink,
  supportLink,
  phoneLink,
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <SC.Top className={cx('Top', className)}>
      <SC.Content>
        <SC.Left>
          {backLink && <SC.Back {...backLink} />}
          <SC.MobileIcon
            icon="message"
            width={24}
            onClick={() => setIsOpen(!isOpen)}
          />
        </SC.Left>
        <SC.Right $isOpen={isOpen}>
          <SC.RightInner>
            <SC.Helpers>
              {supportLink && (
                <SC.Lk {...supportLink}>
                  <SC.MessageIcon
                    icon={Icons.message}
                    color={theme.colors.pureWhite}
                    width={23}
                    height={23}
                  />
                  {supportLink.text}
                </SC.Lk>
              )}
              {phoneLink && (
                <SC.Lk {...phoneLink}>
                  <SC.PhoneIcon
                    icon={Icons.phone}
                    color={theme.colors.pureWhite}
                    width={23}
                    height={23}
                  />
                  {phoneLink.text}
                </SC.Lk>
              )}
            </SC.Helpers>
            {socials}
          </SC.RightInner>
        </SC.Right>
      </SC.Content>
    </SC.Top>
  )
}

export default TopBar

import styled, { css } from 'styled-components'
import dynamic from 'next/dynamic'

import Icon from '../Icon'
import { Wrapper } from '../../form/fields/FormFieldInput/styled'
import { mq, sizes } from '../../theme'
import ActionButton from '../ActionButton'

const FormFieldAutoSuggest = dynamic(
  () => import('../../form/fields/FormFieldAutoSuggest')
)

export const Form = styled.form``
export const Input = styled(FormFieldAutoSuggest)`
  & ${Wrapper} {
    height: 4rem;
    display: flex;
    align-items: center;
    padding: 0.8rem;
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-color: ${({ theme }) => theme.colors.greyContour};

    > div {
      align-items: center;
    }

    @media ${mq(sizes.desktop)} {
      height: 7rem;
      border-radius: 1.2rem;
      min-width: 50rem;
    }
  }
  & input {
    flex: 1;
    padding: 1.8rem 0;

    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.colors.bismark};
    }
  }
  & .react-autosuggest__suggestions-container--open {
    top: 100%;
    border-color: ${({ theme }) => theme.colors.greyChateau};
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    &:before {
      background-color: ${({ theme }) => theme.colors.greyChateau};
    }
  }
`
export const CategoryButton = styled(ActionButton)<{ $isOpen: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.2rem 0.8rem;
  ${({ theme }) => theme.textStyles.montserratSmMedium};
  border: 1px solid ${({ theme }) => theme.colors.bluePrimary};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.bluePrimary};
  flex-shrink: 0;
  background: transparent;

  svg {
    transition: transform 0.3s ease;
  }

  @media ${mq(sizes.desktop)} {
    padding: 1.4rem;
    ${({ theme }) => theme.textStyles.montserratTextBaseMedium};
    border-radius: 15px;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background: ${({ theme }) => theme.colors.bluePrimary};
      color: ${({ theme }) => theme.colors.pureWhite};
      svg {
        transform: rotate(180deg);
      }
    `}
`

export const CategoryIcon = styled(Icon)`
  width: 1.2rem;
  margin-right: 1rem;

  @media ${mq(sizes.desktop)} {
    width: 2rem;
  }
`

export const SearchIcon = styled(Icon)`
  width: 1.6rem;
  color: ${({ theme }) => theme.colors.greyContour};
  margin-left: 0.5rem;

  @media ${mq(sizes.desktop)} {
    width: 2.2rem;
  }
`

import { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { AvatarProps } from '../Avatar'
import { MenuProps } from '../Menu'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type NavigationProps = {
  className?: string
  logo: LinkProps
  menu: MenuProps
  avatar?: AvatarProps
  loginButton?: ActionButtonProps
}

const Navigation: FC<NavigationProps> = ({
  className,
  logo,
  avatar,
  menu,
  loginButton,
}) => {
  return (
    <SC.Nav className={cx('Navigation', className)}>
      <SC.Content>
        <SC.Lk {...logo}>
          <SC.Logo icon="logo" width={200} />
        </SC.Lk>
        <SC.MenuStyled {...menu} />
        {avatar && <SC.UserAvatar {...avatar} />}
        {loginButton && <SC.LoginButton {...loginButton} />}
      </SC.Content>
    </SC.Nav>
  )
}

export default Navigation

import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { createAutocomplete } from '@algolia/autocomplete-core'
import type { SearchClient } from 'algoliasearch'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import '@algolia/autocomplete-theme-classic'

import SearchField, { SearchFieldProps } from '../SearchField'
import { app } from '../../configuration'

export type AutocompleteProps = SearchFieldProps & {
  searchClient?: SearchClient
  autocompleteTransformer: (response: any) => any
}

const Autocomplete: FC<AutocompleteProps> = ({
  searchClient,
  autocompleteTransformer,
  ...searchFieldProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [items, setItems] = useState<Array<any>>([])
  const [autocompleteState, setAutocompleteState] = useState<any>({})

  const autocomplete = useMemo(
    () =>
      searchClient
        ? createAutocomplete({
            onStateChange({ state }) {
              setAutocompleteState(state)
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getSources() {
              return [
                {
                  sourceId: 'querySuggestions',
                  getItemInputValue({ item }) {
                    return item.query
                  },
                  getItems({ query }) {
                    return getAlgoliaResults({
                      transformResponse: (response) =>
                        autocompleteTransformer(response),
                      searchClient,
                      queries: [
                        {
                          indexName: `${app.ALGOLIA_INDEX_PREFIX}::offer.offer`,
                          query,
                          params: {
                            hitsPerPage: 4,
                            highlightPreTag: '<mark>',
                            highlightPostTag: '</mark>',
                          },
                        },
                      ],
                    })
                  },
                  getItemUrl({ item }) {
                    return item.url
                  },
                },
              ]
            },
          })
        : null,
    [searchClient, autocompleteTransformer]
  )

  useEffect(() => {
    if (autocompleteState?.collections?.length) {
      const items = autocompleteState?.collections.reduce(
        (acc: any, item: any) => {
          acc = [...acc, ...item.items]
          return acc
        },
        []
      )
      setItems(items)
    }
  }, [autocompleteState?.collections])

  return (
    <SearchField
      {...searchFieldProps}
      inputRef={inputRef}
      suggestions={items}
      formProps={
        autocomplete?.getFormProps({ inputElement: inputRef.current }) as any
      }
      inputProps={autocomplete?.getInputProps({} as any)}
    />
  )
}

export default Autocomplete

import React, { FC } from 'react'
import cx from 'classnames'

import { SubcategoryListProps } from '../SubcategoryList'
import { IconType } from '../Icon'
import Link, { LinkProps } from '../Link'

import * as SC from './styled'

export type CategoryCardProps = {
  className?: string
  iconCategory?: string
  title: string
  description: string
  list?: SubcategoryListProps
  selected?: boolean
  onClick?: () => void
  link?: LinkProps
}

const CategoryCard: FC<CategoryCardProps> = ({
  className,
  iconCategory,
  title,
  description,
  list,
  selected = false,
  onClick,
  link,
}) => {
  return (
    <SC.Card
      className={cx('CategoryCard', className)}
      onClick={onClick}
      $selected={selected}
    >
      <SC.Visible>
        <SC.ImageWrapper>
          {iconCategory && (
            <SC.IconCategory
              icon={iconCategory as IconType}
              width={24}
              height={24}
            />
          )}
        </SC.ImageWrapper>
        <SC.Content>
          <Link {...link}>
            <SC.Title>{title}</SC.Title>
          </Link>
          <SC.Description>{description}</SC.Description>
        </SC.Content>
      </SC.Visible>
      {list && <SC.List {...list} />}
    </SC.Card>
  )
}

export default CategoryCard

import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type BoxedContainerProps = {
  className?: string
  children?: React.ReactNode
  maxWidth?: 'Xsmall' | 'small' | 'default' | 'none'
  removePadding?: boolean
}

const BoxedContainer: FC<BoxedContainerProps> = ({
  className,
  children,
  maxWidth = 'default',
  removePadding,
}) => {
  return (
    <SC.Container
      className={cx('BoxedContainer', className)}
      $maxWidth={maxWidth}
      $removePadding={removePadding}
    >
      {children}
    </SC.Container>
  )
}

export default BoxedContainer

import React, { FC } from 'react'
import cx from 'classnames'

import { CategoryCardListProps } from './CategoryCardList'
import { CategoriesSidebarProps } from './CategoriesSidebar'
import * as SC from './styled'

export type MegaMenuProps = {
  className?: string
  categories: CategoryCardListProps
  sidebar: CategoriesSidebarProps
  isSidebarOpen?: boolean
}

const MegaMenu: FC<MegaMenuProps> = ({
  className,
  categories,
  sidebar,
  isSidebarOpen,
}) => {
  return (
    <SC.MegaMenu
      className={cx('MegaMenu', className)}
      $isSidebarOpen={isSidebarOpen}
    >
      <SC.Content>
        <SC.Categories {...categories} />
        <SC.Sidebar {...sidebar} />
      </SC.Content>
    </SC.MegaMenu>
  )
}

export default MegaMenu

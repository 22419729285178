import styled from 'styled-components'

import Link from '../../Link'
import SubcategoryList from '../../SubcategoryList'
import Icon from '../../Icon'

export const Sidebar = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  padding: 3.3rem 4rem;
`

export const Head = styled.div``

export const ImgWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.blueBack};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const IconCategory = styled(Icon)``

export const LinkCategory = styled(Link)`
  display: flex;
  align-items: center;
  gap: 2.3rem;
  margin-bottom: 3.7rem;
  &:hover {
    p {
      text-decoration: underline;
    }
  }
`

export const Name = styled.p`
  ${({ theme }) => theme.textStyles.firaSansTextBaseSemiBold};
  color: ${({ theme }) => theme.colors.pureWhite};
  flex: 1;
`

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const List = styled(SubcategoryList)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

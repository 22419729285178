import { FC } from 'react'
import cx from 'classnames'

import { IconProps } from '../Icon'

import * as SC from './styled'

export type ButtonIconProps = IconProps & {
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}

const ButtonIcon: FC<ButtonIconProps> = ({
  className,
  onClick,
  children,
  ...icon
}) => {
  return (
    <SC.Button className={cx('ButtonIcon', className)} onClick={onClick}>
      <SC.IconStyled {...icon} />
      {children}
    </SC.Button>
  )
}

export default ButtonIcon

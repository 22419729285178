import styled from 'styled-components'

import Link from '../Link'
import { mq, sizes } from '../../theme'

export const Footer = styled.footer`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greenSecondaryBis};
  padding: 1.5rem 0;

  @media ${mq(sizes.desktop)} {
    padding: 3.5rem 0;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2.2rem;

  @media ${mq(sizes.desktop)} {
    gap: 2.3rem;
    flex-direction: row;
    align-items: center;
  }
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2.2rem;
  gap: 1.8rem;

  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`

export const Copyright = styled.span`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const TopLinksMenu = styled.div``

export const TopLinksLabel = styled.span`
  ${({ theme }) => theme.textStyles.montserratTextBaseBold};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Separator = styled.hr<{
  $isHiddenDesktop?: boolean
}>`
  margin: 0;
  width: 100%;
  height: 0;
  border: 0;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.pureWhite};

  @media ${mq(sizes.desktop)} {
    display: ${({ $isHiddenDesktop }) =>
      $isHiddenDesktop === true ? `none` : 'block'};
  }
`

export const LinkSeperator = styled.span`
  &:before {
    content: '|';
    display: block;
    font-size: 3rem;
    color: ${({ theme }) => theme.colors.pureWhite};
  }

  display: none;

  @media ${mq(sizes.desktop)} {
    display: block;
  }
`

export const LegalLinks = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 0.8rem;

  @media ${mq(sizes.desktop)} {
    align-items: center;
    flex-direction: row;
    gap: 2.3rem;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const LinkItem = styled(Link)`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.pureWhite};
  transition: color 0.3s;
`

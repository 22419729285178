import styled from 'styled-components'
import Image from 'next/image'

import { mq, sizes } from '../../theme'
import Link from '../Link'

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  @media ${mq(sizes.desktop)} {
    gap: 2.1rem;
  }
`

export const BlockImage = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  @media ${mq(sizes.desktop)} {
    flex-direction: column;
    width: 60px;
    height: 60px;
  }
`

export const RedDot = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.pinkLink};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @media ${mq(sizes.desktop)} {
    transform: translateX(-50%);
  }

  @media ${mq(null, sizes.desktop)} {
    flex-direction: column;
    width: 0.8rem;
    height: 0.8rem;
  }
`

export const BlockInfo = styled.div`
  display: flex;
  text-align: right;
  gap: 0.5rem;

  @media ${mq(sizes.desktop)} {
    flex-direction: column;
  }
`

export const Name = styled.span`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.bluePrimary};
`
export const Surname = styled.span`
  ${({ theme }) => theme.textStyles.montserratSmBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const Lk = styled(Link)`
  position: relative;
`

export const Photo = styled(Image)`
  object-fit: cover;
  object-position: center;
  height: 100%;
`

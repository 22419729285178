import styled, { css } from 'styled-components'

import Link from '../Link'
import Icon from '../Icon'
import ButtonIcon from '../ButtonIcon'

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Top = styled.div``

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0;
  margin: 0;
  padding-top: 3rem;

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    flex-direction: row;
    align-items: center;
    gap: 8rem;
    padding-top: 0;
  }
`

export const Wrapper = styled.div<{ $collapsed?: boolean; $maxHeight: number }>`
  ${({ theme }) => theme.breakpoints.down('desktop')} {
    overflow: hidden;
    transition: max-height 0.5s ease;

    ${({ $collapsed }) =>
      $collapsed &&
      css`
        max-height: 0;
      `};

    ${({ $collapsed, $maxHeight }) =>
      !$collapsed &&
      css`
        max-height: ${$maxHeight}px;
      `};
  }
`

export const Lk = styled(Link)`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.bluePrimary};
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 1.1rem;
  text-align: center;
`

export const Span = styled.span`
  display: flex;
  align-items: center;
`

export const Item = styled.li<{ $isSelected?: boolean }>`
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      ${Lk} {
        color: ${theme.colors.pinkLink};
      }

      ${Span} {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 50%;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: red;
        }
      }
    `};
`

export const ItemIcon = styled(Icon)``

export const Button = styled(ButtonIcon)<{ $collapsed?: boolean }>`
  border: none;
  background-color: transparent;
  padding: 0;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  svg {
    transition: rotate 0.5s ease;
  }

  ${({ $collapsed }) =>
    $collapsed === true &&
    css`
      svg {
        rotate: 180deg;
      }
    `};

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    display: none;
  }
`

export const ButtonText = styled.span<{ $collapsed?: boolean }>`
  ${({ theme }) => theme.textStyles.montserratTextBaseMedium};
  color: ${({ theme }) => theme.colors.bluePrimary};
  opacity: 1;
  transition: opacity 0.5s ease;

  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      opacity: 0;
    `};
`

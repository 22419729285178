import { FC } from 'react'
import cx from 'classnames'

import { SocialProps } from '../Social'

import * as SC from './styled'

export type SocialsProps = {
  className?: string
  items: Array<SocialProps>
}

const Socials: FC<SocialsProps> = ({ className, items }) => {
  return (
    <SC.Socials className={cx('Socials', className)}>
      {items.map((item, idx) => (
        <SC.Item key={idx} {...item} />
      ))}
    </SC.Socials>
  )
}

export default Socials

import styled, { css } from 'styled-components'

import { mq, sizes } from '../../theme'

import CategoryMenu from './CategoryCardList'
import CategorySidebar from './CategoriesSidebar'

export const Categories = styled(CategoryMenu)`
  max-width: var(--boxedContainer-default);
  width: 100%;
  flex-shrink: 0;
  padding-right: 2rem;
  padding-top: 4rem;
`

export const Sidebar = styled(CategorySidebar)`
  padding-left: 8rem;
  display: none;
  width: 100%;
`

export const MegaMenu = styled.div<{ $isSidebarOpen?: boolean }>`
  background: ${({ theme }) => theme.colors.blueBack};
  padding-left: max(2rem, calc((100% - var(--boxedContainer-default)) / 2));

  @media ${mq(sizes.desktop)} {
    ${({ $isSidebarOpen }) =>
      $isSidebarOpen === false &&
      css`
        padding-right: max(
          2rem,
          calc((100% - var(--boxedContainer-default)) / 2)
        );

        ${Categories} {
          padding-right: 0;
        }
      `};

    ${({ $isSidebarOpen }) =>
      $isSidebarOpen === true &&
      css`
        ${Sidebar} {
          display: block;
        }

        ${Categories} {
          @media ${mq(null, 1850)} {
            max-width: 75%;
          }
        }
      `};
  }
`

export const Content = styled.div`
  display: flex;
`

import styled from 'styled-components'

import Link from '../Link'

export const SubcategoryList = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
`

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.pureWhite};
  ${({ theme }) => theme.textStyles.firaSansTextBase};
  &:hover {
    text-decoration: underline;
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

import { FC } from 'react'

import { IconProps, Icons } from '../Icon'
import Link from '../Link'

import * as SC from './styled'

export type SocialProps = Omit<IconProps, 'icon'> & {
  className?: string
  href: string
  icon: keyof Pick<
    typeof Icons,
    | 'postmail'
    | 'socialFacebook'
    | 'socialX'
    | 'socialYoutube'
    | 'socialLinkedin'
    | 'socialInstagram'
  >
}

const Social: FC<SocialProps> = ({ className, icon, href, ...iconProps }) => {
  return (
    <Link href={href} target="_blank">
      <SC.Social {...iconProps} className={className} icon={icon} />
    </Link>
  )
}

export default Social

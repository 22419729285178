import { useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { HeaderProps } from '../../components/HeaderBis'
import { Configuration } from '../../rest/types/Configuration'
import useMegaMenu from '../MegaMenu/useMegaMenu'
import { api, app } from '../../configuration'
import {
  getRouteFromQueryIndex,
  resultsTransformer,
} from '../../helpers/AlgoliaHelpers'
import { actions, selectors } from '../../redux'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { ImageTransformer } from '../Transformers/ImageTransformer'
import { SImage } from '../../rest/types/api/Image'
import { ActionButtonVariant } from '../../components/ActionButton/styled'
import { LoginModalProps } from '../../components/LoginModal'
import Socials from '../../components/Socials'
import { colors } from '../../theme'
import TagLine from '../../components/TagLine'
import Autocomplete, { AutocompleteProps } from '../../components/Autocomplete'
import { Icons } from '../../components/Icon'
import { Page, PageType } from '../../rest/types/Page'

import * as SC from './styled'

const searchClient = algoliasearch(app.ALGOLIA_APP_ID, app.ALGOLIA_API_KEY)

const useHeaderBis = (
  configuration: Configuration,
  pageType?: Page['pageType'],
  isBottomBarDesactivated?: boolean
): HeaderProps => {
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const router = useRouter()
  const [value, setValue] = useState<string>(
    router?.query?.search ? (router?.query?.search as string) : ''
  )

  useEffect(() => {
    if (router?.query?.search) {
      setValue(router?.query?.search as string)
    }
  }, [router?.query?.search])

  const megaMenuProps = useMegaMenu({
    categories: configuration.categories,
    isOpen: isMegaMenuOpen,
    handleClose: () => setIsMegaMenuOpen(false),
  })

  const isConnected = useSelector(selectors.auth.isConnected)
  const hasUnreadMessages = useSelector(selectors.chat.hasUnreadMessages)
  const user = useSelector(selectors.auth.user)
  const { t } = useTranslation()
  const loginModalOpen = useSelector(selectors.app.loginModalOpen)
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleCloseModal = () => {
    dispatch(actions.app.setLoginModalOpen(false))
  }

  const modal: LoginModalProps = {
    title: t('loginModalTitle'),
    isOpen: loginModalOpen,
    onClose: handleCloseModal,
    login: {
      title: t('loginModalLoginTitle'),
      button: {
        href: `${api.API_URL}/api/connect/${api.AUTH_PROVIDER}`,
        variant: ActionButtonVariant.quaternary,
        text: t('loginModalLoginButton'),
      },
    },
    register: {
      title: t('loginModalRegisterTitle'),
      button: {
        text: t('loginModalRegisterButton'),
        href: t('form_path_request_access_central'),
        onClick: handleCloseModal,
      },
    },
  }

  const autocompleteSearchProps: AutocompleteProps = {
    value,
    onChange: (e: any) => setValue(e.target.value),
    onCategoryButtonClick: () => setIsMegaMenuOpen(!isMegaMenuOpen),
    onSubmit: (suggestion: any) => {
      const url = getRouteFromQueryIndex(suggestion.value ?? '')

      if (url) {
        router.push(url)
      }
    },
    onSubmitWithoutSuggestion: () => {
      router.push(Router.getRouteUrl(routes.search, { search: value }))
    },
    isOpen: isMegaMenuOpen,
    autocompleteTransformer: (response: any) =>
      resultsTransformer(response?.results, t),
    searchClient,
    placeholder: t('headerSearchFieldPlaceholder'),
    texts: {
      button: t('headerSearchCategoriesButton'),
    },
  }

  return {
    topBar: {
      backLink: {
        text: t('headerBackLinkText'),
        href: t('headerBackLinkHref'),
        target: '_blank',
      },
      supportLink: {
        text: t('headerTopSupportText'),
        href: t('headerTopSupportLink'),
      },
      phoneLink: {
        text: t('headerTopPhoneText'),
        href: t('headerTopPhoneLink'),
      },
      socials: (
        <Socials
          items={[
            {
              icon: 'postmail',
              width: 21,
              color: colors.pureWhite,
              href: t('headerTopContactLink'),
            },
            {
              icon: 'socialX',
              width: 17,
              color: colors.pureWhite,
              href: t('headerTopTwitterLink'),
            },
            {
              icon: 'socialYoutube',
              width: 16,
              color: colors.pureWhite,
              href: t('headerTopYoutubeLink'),
            },
            {
              icon: 'socialLinkedin',
              width: 16,
              color: colors.pureWhite,
              href: t('headerTopLinkedinLink'),
            },
            {
              icon: 'socialFacebook',
              width: 8,
              color: colors.pureWhite,
              href: t('headerTopFacebookLink'),
            },
            {
              icon: 'socialInstagram',
              width: 16,
              color: colors.pureWhite,
              href: t('headerTopInstagramLink'),
            },
          ]}
        />
      ),
    },
    navigation: {
      logo: {
        href: '/',
      },
      ...(isConnected && {
        avatar: {
          ...(user && {
            photo: ImageTransformer({
              attributes: user?.avatar ?? configuration.config.defaultAvatar,
            } as SImage),
          }),
          showRedDot: hasUnreadMessages,
          name: user?.firstName ?? '',
          surname: user?.lastName ?? '',
          link: {
            href: Router.getRouteUrl(routes.account),
          },
        },
      }),
      ...(!isConnected && {
        loginButton: {
          iconPosition: 'left',
          iconProps: {
            icon: Icons.profileCircle,
          },
          text: t('headerLoginButtonText'),
          href: Router.getRouteUrl(routes.loginOrRegister),
        },
      }),
      menu: {
        title: t('headerMenuTitle'),
        items: [
          {
            icon: {
              icon:
                pageType === PageType.Classifieds
                  ? 'peoples_uncolorred'
                  : 'peoples',
              width: 50,
            },
            ...(pageType && {
              isSelected: pageType === PageType.Classifieds,
            }),
            text: t('headerMenuItemAdsText'),
            href: configuration.config.classifiedsHomepage?.slug
              ? Router.getRouteUrl(routes.page, {
                  slug: configuration.config.classifiedsHomepage.slug,
                })
              : t('headerMenuItemAdsLink'),
          },
          {
            icon: {
              icon: pageType === PageType.Store ? 'bags_uncolorred' : 'bags',
              width: 40,
            },
            isSelected: pageType === PageType.Store,
            text: t('headerMenuItemCentralText'),
            href: configuration.config.storeHomepage?.slug
              ? Router.getRouteUrl(routes.page, {
                  slug: configuration.config.storeHomepage.slug,
                })
              : t('headerMenuItemCentralLink'),
          },
          ...(configuration.menus?.topRight?.entries
            ? configuration.menus?.topRight?.entries.map((entry) => ({
                text: entry.title,
                href: entry.url,
                target: entry.target,
              }))
            : []),
        ],
        isCollapsed,
        onClick: () => setIsCollapsed(!isCollapsed),
      },
    },
    ...(pageType === PageType.Store &&
      !isBottomBarDesactivated && {
        bottomBar: {
          slotLeft: (
            <TagLine
              color={theme.colors.bluePrimary}
              title={t('headerMenuTagLineTitle')}
              texts={[
                t('headerMenuTagLineTextsFirst'),
                t('headerMenuTagLineTextsSecond'),
              ]}
              link={{ href: t('headerMenuTagLineLink'), target: '_blank' }}
            />
          ),
          slotMiddle: (
            <>
              <Autocomplete {...autocompleteSearchProps} />{' '}
            </>
          ),
          slotMegaMenu: <SC.Menu {...megaMenuProps} $isOpen={isMegaMenuOpen} />,
        },
        megaMenu: {
          ...megaMenuProps,
          isOpen: isMegaMenuOpen,
        },
      }),
    modal,
  } as HeaderProps
}

export default useHeaderBis

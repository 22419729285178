import styled, { css } from 'styled-components'

export const Bottom = styled.div`
  background-color: ${({ theme }) => theme.colors.greenSecondary};
  padding: 1.25rem 4rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('desktop')} {
    padding: 1.5rem 3rem;
  }
`

export const Slot = styled.div<{ $hideMobile?: boolean }>`
  flex: 1;
  ${({ $hideMobile, theme }) =>
    $hideMobile &&
    css`
      ${theme.breakpoints.down('desktop')} {
        display: none;
      }
    `};
`
